<!--
 * @Description: 
 * @Author: zhulin
 * @Date: 2021-04-06 11:03:14
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-10 17:08:33
-->
<template>
  <div class="cooperation">
    <div class="banner">
      <!-- <img src="@/assets/images/banner-cooperation.png" alt="" /> -->
      <div class="txt-inner wow bounceInRight">
        <p>我们一起实现更好的平台</p>
        <a href="/Contact">立即体验</a>
      </div>
    </div>
    <div class="section2">
      <div class="inner-title wow fadeInUp"><h6>我们的合作伙伴</h6></div>
      <div class="cent">
        <el-tabs v-model="activeName">
          <el-tab-pane label="咨询机构" name="01">
            <cooperateList :type="1"></cooperateList>
          </el-tab-pane>
          <el-tab-pane label="硬件厂商" name="02">
            <cooperateList :type="2"></cooperateList>
          </el-tab-pane>
          <el-tab-pane label="行业协会" name="03">
            <cooperateList :type="3"></cooperateList>
          </el-tab-pane>
          <el-tab-pane label="金融机构" name="04">
            <cooperateList :type="4"></cooperateList>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="section3">
      <div class="inner-title">
        <h6>我们的客户</h6>
        <p>超过5000+企业成功部署</p>
      </div>
      <div class="cent">
        <cooperateList :type="0"></cooperateList>
      </div>
    </div>
    <div class="section4">
      <contactUs></contactUs>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import cooperateList from "./../components/cooperateList.vue";
import contactUs from "./../components/contactUs.vue";

export default {
  name: "Tooperation",
  components: {
    cooperateList,
    contactUs
  },
  data() {
    return {
      activeName:"01",
      companyLis: [
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};
</script>
<style lang="scss" scoped>
.banner {
  background: url("~@/assets/images/banner-cooperation.jpg") center top no-repeat;
  height: 618px;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    bottom: 64px;
    margin-left: -480px;
    display: block;
  }
  .txt-inner {
    text-align: center;
    padding-top:230px;
    h3 {
      font-size: 48px;
      margin: 0;
    }
    p {
      font-size: 48px;
      letter-spacing: 12px;
    }
    a{
      display: inline-block;
      width:335px;
      height:50px;
      line-height: 50px;
      color:#fff;
      background:#0183de;
      font-size:18px;
      border-radius:5px;
      font-size:18px;
      margin-top:30px;
      text-decoration: none;
    }
  }
}
/deep/ .el-tabs__item {
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px!important;
}
/deep/ .el-tabs__nav {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e8ecf2;
  height: 60px;
}
/deep/ .el-tabs__nav-scroll{
  overflow: inherit;
}
/deep/ .el-tabs__active-bar {
  height: 0;
}
/deep/ .el-tabs__item.is-active {
  color:#0183de;
  border-bottom:2px solid #0183de;
}
/deep/ .el-tabs__item:hover {
  color:#0183de;
}

.section3{
  background:#f1f6ff;
}
.section4{
  color:#333;
}
.inner-title {
  color: #333333;
  padding-top: 66px;
  line-height: 34px;
  h6{
    font-size: 30px;
    font-weight: bold;
    margin: 0;
  }
  p{
    font-weight: normal;
    font-size:24px;
  }
}
.cent {
  color: #333;
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}
.section2{
  margin: 0 auto;
  /deep/ .cooperate-list dl{
    border: 2px solid #e8ecf2;
  }
  /deep/ .el-tabs__content{
    margin:40px 0 20px;
  }
}
@media (max-width: 750px) {
  .section2{
    margin: 0 auto;
    .cent{
      padding-left:0;
      padding-right:0;
    }
    /deep/ .cooperate-list dl{
      width: 45%;
    }
    /deep/ .el-tabs__content{
      margin: 30px 0 0;
    }
  }
  .section3{
    /deep/ .cooperate-list dl{
      width: 45%;
      margin: 10px 0 10px 10px;
    }
  }
  /deep/ .el-tabs__item{
    font-size:16px;
    font-weight: bold;
    line-height:66px;
    padding: 0!important;
  }
  .banner {
    height:190px;
    overflow: hidden;
    background-size: 201%;
    img {
      width: 60%;
      margin-left: -30%;
      bottom: -20px;
    }
    .txt-inner {
      padding: 0;
      p {
        font-size: 22px;
        margin-top:60px;
        text-align: center;
        letter-spacing: 0;
      }
      a {
        width: 127px;
        height: 30px;
        line-height: 30px;
        margin-top: 0;
        font-size: 14px;
      }
    }
  }
  .inner-title {
    padding-top:30px;
    font-size: 20px;
    padding-bottom: 0;
    h6{
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    }
    p{
      font-size: 20px;
    }
  }
  .cent {
    width: 90%;
    padding-top:0;
  }
}
</style>
